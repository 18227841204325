import React, { useState } from "react";
import { Link } from "react-router-dom";
import { truncateAuthor, formatDate } from "../utils/helpers";
import eye from "../asset/eye-fill.svg"

const NewsItem = (props) => {
  let { title, description, imgUrl, newsUrl, author, date, category, isSmall, id } =
    props;
  const [isHovered, setIsHovered] = useState(false);

  const randomCount = description?.length;//mock count

  return isSmall ? (
    <li
      className="list-group-item list-group-size text-dark px-4"
      id="max-lines-description"
      title={title}
    >
      <Link
        to={`/cat/${encodeURIComponent(category)}/${id}/${encodeURIComponent(title)}`}
        className="link-custom"
      >
        <small className="d-flex small text-black-50 mb-1">
          {new Date(date).toLocaleString()}
        </small>
        <span className="hover-link">{title}</span>
      </Link>
    </li>
  ) : (
    <div className="my-3">
      <Link
        className="link-custom"
        to={`/cat/${encodeURIComponent(category)}/${id}/${encodeURIComponent(title)}`}
      >
        <div className="card card-style">
          <div className="image-container" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <img
              src={imgUrl}
              className="card-img-top image"
              alt={title}
            />
            {isHovered && (
              <div className="counter">
                <img src={eye} alt=".." className="eye"/>
                <span className="text-eye">{randomCount}</span>
              </div>
            )}
          </div>
          <div className="card-body pt-1 card-body-custom">
            {author ? (
              <small className="text-black text-truncate" title={author}>
                {truncateAuthor(author)}
              </small>
            ) : null}
            {author ? <span id="dot-small" /> : null}
            <small className="text-muted">{formatDate(date)}</small>
            <h5
              className="card-title hover-link"
              id="max-lines-title"
              title={title}
            >
              {title}
            </h5>
            <p
              className="card-text hover-link"
              id="max-lines-description"
              title={description}
            >
              {description}
            </p>
            <a
              href={newsUrl}
              target="_blank"
              rel="nofollow noopener noreferrer"
              className="btn btn-sm btn-outline-dark"
            >
              Read more
            </a>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default NewsItem;
