import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getDetailNews } from "../api/getNews";
import Spinner from "./Spinner";
import { API_URL } from "../api/api";

const NewsDetail = (props) => {
  const { category, id } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNewsDetail = async () => {
      try {
        setLoading(true);
        const matchingArticle = await getDetailNews(
          id,
        );

        if (matchingArticle) {
          setArticle(matchingArticle);
          document.title = matchingArticle.title;
        } else {
          console.error("Article not found");
        }
      } catch (error) {
        console.error("Error fetching news detail:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNewsDetail();
  }, [id]);

  const handleGoBack = () => {
    if (category === "general") {
      navigate(`/`);
    } else {
      navigate(`/${category}`);
    }
  };

  if (loading) {
    return (
      <div className="center-container">
        <Spinner />
      </div>
    );
  }

  if (!article) {
    return (
      <div className="center-container">
        <p>
          Oops, something went wrong.
          <span className="back-icon" onClick={handleGoBack}>
            <span className="m-1">Go back</span>
          </span>
        </p>
      </div>
    );
  }
  return (
    <div className="mv-100">
      <div className="container">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <div className="card border-transp">
              <img
                src={
                  API_URL + "/article_images/" + article.image_name
                }
                className="card-img-top"
                alt={article.title}
              />
              <div className="card-body p-0 pt-2">
                <h2 className="card-title">{article.title}</h2>
                <p className="card-text">{article.content}</p>
                <p className="card-text">
                  <strong>Published Date:</strong>{" "}
                  {new Date(article.pubDate).toLocaleString()}
                </p>
                <a
                  href={article.link}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  className="btn btn-outline-dark"
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsDetail;
