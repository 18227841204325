import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function formatDate(dateString) {
  dayjs.extend(relativeTime);
  const date = dayjs(dateString);
  return date.fromNow();
};
function truncateAuthor(author) {
  if (author.length > 15) {
    return author.slice(0, 15) + "...";
  }
  return author;
};

export { capitalizeFirstLetter, formatDate, truncateAuthor };
