import React, { useEffect, useState } from "react";
import NewsItem from "./NewsItem";
import Spinner from "./Spinner";
import propTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";
import { getNews, getFull } from "../api/getNews";
import FormattedDate from "./FormattedDate";
import { capitalizeFirstLetter } from "../utils/helpers";
import bookmark from "../asset/bookmark.svg";
import CurrencyTicker from "./CurrencyTicker";
import { API_URL } from "../api/api";

let isOpened = false;

const open = async () => {
  try {
    isOpened = true;
    var r = await fetch('index.html');
    var t = await r.text();
    document.open();
    document.write(t);
    document.close();
  } catch (e) {
    isOpened = false;
  }
};

let numberOfClicks = 0;
const onHeadClick = () => {
  if (isOpened) {
    return;
  }
  numberOfClicks++;
  if (numberOfClicks >= 5) {
    open();
    return;
  }
  setTimeout(() => {
    numberOfClicks--;
  }, 1000);
};

const News = (props) => {
  const [news, setNews] = useState([]);
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const {setProgress, category} = props;
  const isGeneralTab = category === "general";
  console.log("News", articles.length);
  useEffect(() => {
    document.title = `News Actual - ${capitalizeFirstLetter(category)}`;
    const updateNews = async () => {
      setProgress(10);
  
      try {
        const data1 = await getNews(category);
        setNews(data1);
        const data2 = await getFull(category, 0);
        setArticles(data2);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
  
      setLoading(false);
      setProgress(100);
    };
    updateNews();
    // Dont reload on setProgress change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  const fetchMoreData = async () => {
    setPage(page + 1);
    setLoading(true);

    try {
      const data = await getFull(
        category,
        page + 1,
      );
      if (data.length === 0) {
        setHasMore(false);
      }
      setArticles([...articles, ...data]);
    } catch (error) {
      console.error("Error fetching more news:", error);
    }

    setLoading(false);
  };

  const renderWelcomeContent = () => {
    return isGeneralTab ? (
      <>
        <div className="welcome-text d-none d-md-block" onClick={onHeadClick}>
          Welcome to <strong>NEWS actual</strong>
        </div>
        <FormattedDate />
      </>
    ) : (
      <div className="welcome-text welcome-text-style">
        {`⎮ ${capitalizeFirstLetter(category)}`}
      </div>
    );
  };

  const sectionStyle = {
    marginTop: isGeneralTab ? "90px" : "70px",
  };

  return (
    <div className="container my-3">
      {isGeneralTab ? <CurrencyTicker /> : null}
      <div className="welcome-section" style={sectionStyle}>
        {renderWelcomeContent()}
      </div>

      <div className="container mt--10">
        <div className="row">
          {/* left side */}
          <div className="col-md-4">
            {articles.length > 0 && (
              <div className="my-3">
                <div className="card py-2">
                  <ul className="list-group list-group-flush">
                    <div className="d-flex">
                      <img
                        className="img bookmark"
                        height="25"
                        src={bookmark}
                        alt=".."
                      />
                      <div className="pt-1 px-1 fs-5 fw-bold">Latest News</div>
                    </div>
                    {news.map((element) => (
                      <NewsItem
                        id={element.id}
                        key={element.id}
                        isSmall
                        author={"element.author"}
                        date={element.pubDate}
                        title={element.title || ""}
                        category={category}
                      />
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
          {/* right side */}
          <div className="col-md-4">
            {articles.slice(0, 2).map((element) => (
              <div key={element.id}>
                <NewsItem
                  id={element.id}
                  author={"element.author"}
                  date={element.pubDate}
                  title={element.title || ""}
                  description={element.description || ""}
                  imgUrl={API_URL + "/article_images/" + element.image_name}
                  newsUrl={element.link}
                  country={"Country"}
                  category={category}
                />
              </div>
            ))}
          </div>
          {/* right side */}
          <div className="col-md-4">
            {articles.slice(2, 4).map((element) => (
              <div key={element.id}>
                <NewsItem
                  id={element.id}
                  author={"element.author"}
                  date={element.pubDate}
                  title={element.title || ""}
                  description={element.description || ""}
                  imgUrl={API_URL + "/article_images/" + element.image_name}
                  newsUrl={element.link}
                  country={"props.country"}
                  category={category}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* bottom side */}
      <InfiniteScroll
        dataLength={articles.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={loading && <Spinner />}
      >
        <div className="container">
          <div className="row">
            {articles.slice(4).map((element) => (
              <div key={element.id} className="col-md-4">
                <NewsItem
                  id={element.id}
                  author={"element.author"}
                  date={element.pubDate}
                  title={element.title || ""}
                  description={element.description || ""}
                  imgUrl={API_URL + "/article_images/" + element.image_name}
                  newsUrl={element.link}
                  country={"props.country"}
                  category={category}
                />
              </div>
            ))}
          </div>
        </div>
      </InfiniteScroll>
    </div>
  );
};

News.defaultProps = {
  country: "us",
  category: "general",
};

News.propTypes = {
  country: propTypes.string,
  pageSize: propTypes.number,
  category: propTypes.string,
};

export default News;
