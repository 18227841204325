import { fetchData } from "./api";

// author: "Chris Otts"
// content: "LOUISVILLE, Ky. (WDRB) Workers at Fords two Louisville vehicle plants voted against the proposed UAW contract by a 52-to-48 margin, bucking the national trend so far.\r\nUAW figures show 5,766 Kentucky… [+4113 chars]"
// description: "It’s too soon to say what result means for the nationwide tally."
// publishedAt: "2023-11-14T18:12:03Z"
// source: id: null name: "WDRB"
// title: "Louisville Ford workers vote against UAW contract | Business - WDRB"
// url: "https://www.wdrb.com/news/business/louisville-ford-workers-vote-against-uaw-contract/article_a069d096-818b-11ee-be87-2f4682f364fe.html"
// urlToImage: "https://bloximages.new

const getNews = async (category) => {
  const url = `/news?category=${category}`;
  const data = await fetchData(url);
  console.log(data);
  return fetchData(url);
};

const getFull = async (category, page) => {
  const url = `/full?category=${category}&page=${page}`;
  const data = await fetchData(url);
  console.log(data);
  return fetchData(url);
};

const getDetailNews = async (id) => {
  const url = `/article/${id}`;
  const data = await fetchData(url);
  return data;
};

export { getNews, getFull, getDetailNews };
