import React from "react";
import loading from "../asset/spinner.svg";

const Spinner = () => {
  return (
    <div className="text-center my-3">
      <img className="img" height="100" src={loading} alt=".." />
    </div>
  );
};

export default Spinner;
