import React, { useState, useEffect } from 'react';
import { fetchCurrencyRates } from '../api/fetchCurrencyRates';

const CurrencyTicker = () => {
  const [rates, setRates] = useState(null);

  const fetchRates = async () => {
    try {
      const ratesData = await fetchCurrencyRates();
      setRates(ratesData);
      localStorage.setItem('cachedRates', JSON.stringify(ratesData));
    } catch (error) {
      console.error("Error fetching currency rates:", error);
      setRates(null);
    }
  };

  useEffect(() => {
    const cachedRates = localStorage.getItem('cachedRates');
    if (cachedRates) {
      setRates(JSON.parse(cachedRates));
    } else {
      fetchRates();
    }

    const fetchInterval = setInterval(fetchRates, 3600000);

    return () => {
      clearInterval(fetchInterval);
    };
  }, []);

  if (rates === null) {
    return null;
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-distracting-elements
    <marquee direction="left" scrollamount="2" className="currency-ticker">
      {Object.keys(rates).length > 0 && (
        <span>
          {Object.keys(rates).map(currency => (
            <span key={currency} className='currency-rate'>
              {`USD/${currency}:`}{" "}
              <span key={currency} className='currency-rate'>
              {rates[currency].toFixed(4)}{" "}
              </span>
            </span>
          ))}
        </span>
      )}
    </marquee>
  );
};

export default CurrencyTicker;
