import "./App.css";

import React, { useState, useEffect } from "react";
import NavBar from "./component/NavBar.js";
import News from "./component/News.js";
import NewsDetail from "./component/NewsDetail.js";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import {getCategories} from "./api/getCategories.js";

const App = () => {
  const [progress, setProgress] = useState(0);
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    async function queryCategories() {
      setCategories(await getCategories());
    }
    queryCategories();
  }, []);
  if (categories.length === 0) {
    return null;
  }
  return (
    <div>
      <>
        <Router>
          <NavBar />
          <LoadingBar height={3} color="#fd6638" progress={progress} />
          <Routes>
            <Route
              exact
              path="/"
              element={
                <News
                  setProgress={setProgress}
                  category="general"
                />
              }
            />
            {categories.map(c =>
              <Route
                exact
                path={`/cat/${c.id}`}
                element={
                  <News
                    setProgress={setProgress}
                    key={c.id}
                    category={c.id}
                  />
                }
            />
            )}
            <Route
              path="/cat/:category/:id/:title"
              element={<NewsDetail />}
            />
          </Routes>
        </Router>
      </>
    </div>
  );
};
export default App;
