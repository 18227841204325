import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../asset/logo.svg";
import WeatherDisplay from "./Weather";
import { getCategories } from "../api/getCategories";

const CategoryItem = ({id, name}) => (
  <li className="nav-item">
    <NavLink to={`/cat/${id}`} className="nav-link">
      {name}
    </NavLink>
  </li>
);

const NavBar = () => {
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    async function queryCategories() {
      setCategories(await getCategories());
    }
    queryCategories();
  }, []);
  if (categories.length === 0) {
    return null;
  }
  return (
    <div>
      <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-black px-lg-5">
        <div className="container-fluid">
          <div className="d-md-flex">
          <NavLink className="navbar-brand" to="/">
            <img src={logo} alt="News Actual Logo" className="logo-height" />
          </NavLink>
          <WeatherDisplay city="Kiev" weatherType="stormy" temperatureSign="+" temperature='6' temperatureUnit='C'/>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse flex-md-grow-0"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink to="/" className="nav-link">
                  Home
                </NavLink>
              </li>
              {categories.map((c, i) => <CategoryItem key={i} {...c} />)}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
