const apiKey = "4PE3J560jiX9ZWipwoFiWCL5jxLB1K9n";

export const fetchCurrencyRates = () => {
  return fetch(`https://api.apilayer.com/fixer/latest?base=USD&apikey=${apiKey}`)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => data.rates)
    .catch((error) => {
      console.error("Error fetching currency rates:", error);
      throw error;
    });
};
