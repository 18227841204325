import React from "react";
import cloudy from "../asset/weather-icons/weather-cloudy.svg";
import downpour from "../asset/weather-icons/weather-downpour.svg";
import night from "../asset/weather-icons/weather-night.svg";
import partlySunny from "../asset/weather-icons/weather-partly-sunny.svg";
import shower from "../asset/weather-icons/weather-shower.svg";
import snow from "../asset/weather-icons/weather-snow.svg";
import stormy from "../asset/weather-icons/weather-stormy.svg";
import sunny from "../asset/weather-icons/weather-sunny.svg";
import windyCloudy from "../asset/weather-icons/weather-windy-cloudy.svg";
import windy from "../asset/weather-icons/weather-windy.svg";

const weatherIcons = {
  cloudy,
  downpour,
  night,
  partlySunny,
  shower,
  snow,
  stormy,
  sunny,
  windyCloudy,
  windy,
};

function WeatherDisplay(props) {
  const { city, temperature, weatherType, temperatureSign, temperatureUnit } = props;

  const weatherImage = weatherIcons[weatherType];

  return (
    <div className="d-flex align-items-center justify-content-md-center">
      <div className="mx-2 d-none d-md-flex">⎮</div>
      <a
        className="text-light text-decoration-none mx-md-2 fs-6"
        href="https://www.accuweather.com/en"
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        {city}
      </a>
      {weatherImage && <img src={weatherImage} alt={weatherType} />}
      <div className="text-light mx-2 fs-7">
        {temperatureSign}
        {temperatureUnit === "F" ? (temperature * 9/5 + 32) : temperature}°{temperatureUnit}
      </div>
    </div>
  );
}

export default WeatherDisplay;